import React, { useEffect, useRef, useState } from "react";
import styles from "./review-payment.module.scss"
import TGCheckBox from "../../../../shared/tg-checkbox";
import TGIcon from "../../../../shared/tg-icon";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import TGModal from "../../../../shared/tg-modal";

const ReviewPayment: React.FC = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const termConditionRef = useRef<any>(null);
  const handleContinue = () => {
    console.log("test")
  }
  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      if (value == true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };
  const handleClose = () => {
    setShowTerms(false);
  };
  const handleTerms = () => {
    setShowTerms(true);
  };
  const { termsConditionData, dropDowndata } = useSelector(
    (state: RootState) => state?.osciReducer?.giftMiles
  );
  return <>
    <div className={styles["container"]}>
      <div className={styles["paymentSummary"]}>
        <div>
          <div className={styles["reviewPaymentheader"]}>Review & Payment</div>
          <div className={styles["reviewPaymentsubheading"]}>Please review all details and accept the Terms & Conditions before proceeding.</div>
        </div>
        <div className={styles["divider"]}></div>
        <div className={styles["purchaseDetails"]}>
          <div className={styles["header"]}>
            <div className={styles["heading"]}>Purchase Details</div>
            <div className={styles["edit"]}>
              <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
              <div className={styles["editButton"]}>Edit</div>
            </div>
          </div>
          <div className={styles["content"]}>
            <div className={styles["contentRow"]}>
              <div className={styles["subContent"]}>
                <div className={styles["key"]}>Currency</div>
                <div className={styles["value"]}>THB</div>
              </div>
              <div className={styles["subContent"]}>
                <div className={styles["key"]}>Purchase Amount</div>
                <div className={styles["value"]}>1,000 Miles (1,200 THB)</div>
              </div>
            </div>
            <div className={styles["contentRow"]}>
              <div className={styles["subContent"]}>
                <div className={styles["key"]}>Payment Method</div>
                <div className={styles["value"]}>Credit Card</div>
              </div>
            </div>

          </div>
        </div>
        <div className={styles["divider"]}></div>
        <div className={styles["content"]}>
          <div className={styles["header"]}>
            <div className={styles["heading"]}>Recipient Member Details </div>
            <div className={styles["edit"]}>
              <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
              <div className={styles["editButton"]}>Edit</div>
            </div>
          </div>
          {/* <div className={styles["recipientContent"]}> */}
          <div className={styles["contentRow"]}>
            <div className={styles["subContent"]}>
              <div className={styles["key"]}>Recipient Member Name</div>
              <div className={styles["value"]}>John Doe</div>
            </div>
            <div className={styles["subContent"]}>
              <div className={styles["key"]}>Recipient Royal Orchid Plus Member ID</div>
              <div className={styles["value"]}>ZZ00005</div>
            </div>
          </div>
        </div>
        <div className={styles["divider"]}></div>
        <div className={styles["content"]}>
          <div className={styles["mainTandC"]}>
            <TGCheckBox 
            onChange={checkedChange}
            checkref={termConditionRef}
            checked={isChecked}
            className="checkLabel"
            customLabel="checkLabel"
            />
            <span className={styles["textTandC"]}>I accept all <span className={styles["highlightTandC"]} onClick={handleTerms}>Terms & Conditions</span></span>
            {showTerms && (
              <TGModal
                show={showTerms}
                handleClose={handleClose}
                centered
                customOffCanvasClass={styles["notice-bar-offcanvas"]}
                customModalClass={styles["terms-header"]}
              >
                <div className={styles["terms-condition"]}>
                  <h3
                    className={styles["terms-header"]}
                    dangerouslySetInnerHTML={{
                      __html: termsConditionData?.header,
                    }}
                  ></h3>
                  <div
                    className={styles["terms-content"]}
                    dangerouslySetInnerHTML={{
                      __html: termsConditionData?.content,
                    }}
                  ></div>
                </div>
              </TGModal>
            )}
          </div>
        </div>
      </div>
      <TGButtonVariants
        label="Proceed to Payment"
        borderRadius="30px"
        //bgColor="var(--Gradient-brand-surface, linear-gradient(99deg, #684B9B -27.72%, #381B6B 127.08%))"
        bgColor="#684B9B"
        bgColorHover="#684B9B"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        display= "flex"
        padding= "10px 40px"
        justifyContent= "center"
        alignItems= "center"
        //alignItems= "stretch"
        textColor= "#FFFFFF"
        fontFamily= "Inter"
        fontSize= "16px"
        fontStyle= "normal"
        fontWeight= "700"
        lineHeight= "160%"
        height="61px"
        //variant="primary"
        //customClassName={styles["review-passenger-continue-button"]}
        onClick={handleContinue}
      />
    </div>
  </>
};

export default ReviewPayment
