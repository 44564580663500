import React, { useEffect, useState } from "react";
import styles from "./gift-miles.module.scss";
import { ResetUnlockBanner } from "../../pages/reset-unlock/banner";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import TGInput from "../../../../shared/tg-input";
import TGButton from "../../../../shared/tg-button";
import ReviewPayment from "./reviewPayment";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../../config/global";
import {
  getGiftMilesDropdownRequest,
  giftMilesTermsConditionFailure,
  giftMilesTermsConditionSuccess,
  reviewSummaryRequest,
} from "../../slice/giftMilesSlice";
import { RootState } from "store";
import TGModal from "../../../../shared/tg-modal";
import { TGRadio } from "../../../../shared/tg-radio";
import TGSelection from "../../../../shared/tg-selection";
import { isEmptyChecker, isValidRopId } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";

const GiftMiles: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [giftDetails, setGiftDetails] = useState<any>({
    firstName: "",
    lastName: "",
    receipientROPId: "",
    currency: "THB",
    giftingMiles: "1000",
  });
  const [errors, setError] = useState<any>({
    firstName: { value: false, errorLabel: "" },
    lastName: { value: false, errorLabel: "" },
    receipientROPId: { value: false, errorLabel: "" },
  });
  const [giftingMiles, setGiftingMiles] = useState<any>({
    amountThb: 1200,
    amountUsd: 35,
    id: 1200,
    listName: "1,000 Miles (1,200 THB)",
    mileage: 1000,
  });

  const [isPaymentPage, setPaymentPage] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [paymentSelect, setPaymentSelect] = useState<number>(1);
  const { termsConditionData, dropDowndata } = useSelector(
    (state: RootState) => state?.osciReducer?.giftMiles
  );
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const getCountryFromUrl = i18n.language?.toUpperCase();

  const dropdownList =
    dropDowndata?.amount?.length > 0
      ? dropDowndata?.amount?.map(
          (amount: {
            amountThb: number;
            amountUsd: number;
            mileage: number;
          }) => {
            return [
              {
                THB: {
                  ...amount,
                  id: amount?.amountThb,
                  listName: `${amount?.mileage.toLocaleString(
                    getCountryFromUrl
                  )} Miles (${amount?.amountThb.toLocaleString(
                    getCountryFromUrl
                  )} THB)`,
                },
                USD: {
                  ...amount,
                  id: amount?.amountUsd,
                  listName: `${amount?.mileage.toLocaleString(
                    getCountryFromUrl
                  )} Miles (${amount?.amountUsd.toLocaleString(
                    getCountryFromUrl
                  )} USD)`,
                },
              },
            ];
          }
        )
      : [];
  const USDList = dropdownList?.map((item: any) => item[0]?.USD);
  const THBList = dropdownList?.map((item: any) => item[0]?.THB);

  const TERMS_URL = config.TEAMSITE_GIFT_MILES_TERMS;
  useEffect(() => {
    dispatch(getGiftMilesDropdownRequest());
  }, []);
  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: TERMS_URL,
      isTeamsite: true,
      successAction: giftMilesTermsConditionSuccess,
      errorAction: giftMilesTermsConditionFailure,
    });
  }, [dispatch]);
  const handleInputFieldChange = (e: any) => {
    const { name, value } = e?.target;
    setGiftDetails({ ...giftDetails, [name]: value });
    setError({ ...errors, [name]: { value: false, errorLabel: "" } });
  };
  const handlePaymentSlect = (e: any) => {
    if (e?.value === "USD") {
      setGiftDetails({ ...giftDetails, currency: "USD", giftingMiles: "1000" });
    }
    setPaymentSelect(e?.id);
  };

  const handleClose = () => {
    setShowTerms(false);
  };
  const handleTerms = () => {
    setShowTerms(true);
  };
  const handleSelect = (
    e: any,
    option: {
      id: number;
      amountThb: number;
      amountUsd: number;
      mileage: number;
      listName: string;
    }
  ) => {
    setGiftingMiles(option);
    console.log(paymentSelect, option, "pay");
    if (paymentSelect === 2) {
      console.log("OUT");
      setGiftDetails({
        ...giftDetails,
        currency: "USD",
        giftingMiles: option?.mileage,
      });
    } else if (paymentSelect === 1) {
      console.log("IN");
      setGiftDetails({
        ...giftDetails,
        currency: "THB",
        giftingMiles: option?.mileage,
      });
    }
  };

  const handleContinue = () => {
    const newErrors: Partial<any> = {};
    for (const key in giftDetails) {
      if (isEmptyChecker(giftDetails[key])) {
        if (key === "firstName") {
          newErrors[key] = {
            value: true,
            errorLabel: t("error_parent_middle_name_required"),
          };
        } else if (key === "lastName") {
          newErrors[key] = {
            value: true,
            errorLabel: t("error_reset_last_name_required"),
          };
        } else if (key === "receipientROPId") {
          newErrors[key] = {
            value: false,
            errorLabel: "",
          };
        }
      }
    }

    if (
      Object.values(giftDetails["firstName"]).length > 0 &&
      Object.values(giftDetails["firstName"]).length < 2
    ) {
      newErrors["firstName"] = {
        value: true,
        errorLabel: t("error_min_length_first_name"),
      };
    }

    if (
      Object.values(giftDetails["lastName"]).length > 0 &&
      Object.values(giftDetails["lastName"]).length < 2
    ) {
      newErrors["lastName"] = {
        value: true,
        errorLabel: t("error_min_length_last_name"),
      };
    }

    if (
      Object.values(giftDetails["receipientROPId"]).length > 0 &&
      Object.values(giftDetails["receipientROPId"]).length < 7
    ) {
      newErrors["receipientROPId"] = {
        value: true,
        errorLabel: t("error_label_valid_receipient_id"),
      };
    } else if (Object.values(giftDetails["receipientROPId"]).length === 0) {
      newErrors["receipientROPId"] = {
        value: true,
        errorLabel: t("error_label_receipient_id_required"),
      };
    } else {
      newErrors["receipientROPId"] = {
        value: false,
        errorLabel: "",
      };
    }
    setError(newErrors);
    if (Object.values(newErrors).some((error) => error.value === false)) {
      dispatch(reviewSummaryRequest(giftDetails));
      console.log(giftDetails, "RESPONSE999");
    }
  };

  return (
    <ResetUnlockBanner
      imageText="Gift Miles"
      banner={
        isDesktopView
          ? styles["giftMilesContainer"]
          : styles["mobileGiftMilesContainer"]
      }
    >
      <>
        {!isPaymentPage ? (
          <div className={styles["container"]}>
            <div>
              <div className={styles["header"]}>
                {t("label_gift_miles_header")}
              </div>
              <div className={styles["content-container"]}>
                <div className={styles["content"]}>{t("label_gift_miles")}</div>
                <div className={styles["content"]}>
                  {t("label_gift_miles_range")}
                </div>
                <div className={styles["content"]}>
                  {t("label_please_refer")}{" "}
                  <span
                    className={styles["termscondition"]}
                    onClick={handleTerms}
                  >
                    {t("profile_terms")}.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles["divider"]}></div>
            <div className={styles["amountContainer"]}>
              <div className={styles["header"]}>{t("label_gift_amount")}</div>
              <div className={styles["amountOptions"]}>
                <div>{t("label_payment_method")}</div>
                <div>{t("label_credit_card")}</div>
              </div>
              <div className={styles["payment-currency-container"]}>
                <div
                  className={`${
                    paymentSelect === 1
                      ? styles["active-tab"]
                      : styles["inActive-tab"]
                  }`}
                >
                  <div className={styles["radio-btn"]}>
                    <TGRadio
                      name="payment-option-THB"
                      options={[
                        {
                          id: 1,
                          label: [],
                          value: "THB",
                        },
                      ]}
                      onChange={(e: any) => {
                        handlePaymentSlect(e);
                      }}
                      isBorder={false}
                      selected={paymentSelect}
                    />
                  </div>
                  <div className={styles["dropdown-container"]}>
                    <div
                      className={`${
                        paymentSelect === 1
                          ? styles["active-label"]
                          : styles["inActive-label"]
                      }`}
                    >
                      {t("label_pay_THB")}
                    </div>
                    {paymentSelect === 1 && (
                      <div className={styles["drop-down-option"]}>
                        <TGSelection
                          label={t("label_gift_amount")}
                          selectMenuBar={styles["custom-dropdown-option"]}
                          isLoading={false}
                          listData={THBList}
                          onSelect={(e, obj) => {
                            handleSelect(e, obj);
                          }}
                          value={giftingMiles?.listName}
                          toLowerCase={true}
                          disableSearch={true}
                          headerLabel={t("label_gift_amount")}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    paymentSelect === 2
                      ? styles["active-tab"]
                      : styles["inActive-tab"]
                  }`}
                >
                  <div className={styles["radio-btn"]}>
                    <TGRadio
                      name="payment-option-USD"
                      options={[
                        {
                          id: 2,
                          label: [],
                          value: "USD",
                        },
                      ]}
                      onChange={(e: any) => {
                        handlePaymentSlect(e);
                      }}
                      isBorder={false}
                      selected={paymentSelect}
                    />
                  </div>
                  <div className={styles["dropdown-container"]}>
                    <div
                      className={
                        paymentSelect === 2
                          ? styles["active-label"]
                          : styles["inActive-label"]
                      }
                    >
                      {t("label_pay_USD")}
                    </div>
                    {paymentSelect === 2 && (
                      <div className={styles["drop-down-option"]}>
                        <TGSelection
                          label={t("label_gift_amount")}
                          selectMenuBar={styles["custom-dropdown-option"]}
                          listData={USDList}
                          isLoading={false}
                          onChange={(e, obj) => handleSelect(e, obj)}
                          value={giftingMiles?.listName}
                          toLowerCase={true}
                          disableSearch={true}
                          headerLabel={t("label_gift_amount")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["amountContainer"]}>
              <div className={styles["header"]}>
                {t("label_gift_field_header")}
              </div>
              <div className={styles["inputFields"]}>
                <div>
                  <TGInput
                    label={t("enroll_firstname")}
                    onChange={(e) => handleInputFieldChange(e)}
                    isError={errors.firstName?.value}
                    errorLabel={errors.firstName?.errorLabel}
                    name="firstName"
                    value={giftDetails?.firstName}
                    placeholder={""}
                    customClassName={styles.fieldContainer}
                    maxLength={40}
                    validation="name"
                  />
                </div>
                <div>
                  <TGInput
                    label={t("enroll_lastName")}
                    onChange={handleInputFieldChange}
                    isError={errors.lastName?.value}
                    errorLabel={errors.lastName?.errorLabel}
                    name="lastName"
                    value={giftDetails?.lastName}
                    placeholder={""}
                    customClassName={styles.fieldContainer}
                    maxLength={40}
                    validation="name"
                  />
                </div>
              </div>
              <div className={styles["inputFields"]}>
                <div>
                  <TGInput
                    label={t("Recipient Royal Orchid Plus Member ID ")}
                    onChange={handleInputFieldChange}
                    isError={errors.receipientROPId?.value}
                    errorLabel={errors.receipientROPId?.errorLabel}
                    name="receipientROPId"
                    value={giftDetails?.receipientROPId}
                    customClassName={styles.fieldContainer}
                    validation="rop"
                  />
                </div>
                <div></div>
              </div>
            </div>
            <div className={styles["btn-container"]}>
              <TGButton
                label={t("button_reset_continue")}
                variant={"primary"}
                customClassName={styles["continue-btn"]}
                onClick={handleContinue}
              />
            </div>
            {showTerms && (
              <TGModal
                show={showTerms}
                handleClose={handleClose}
                centered
                customOffCanvasClass={styles["notice-bar-offcanvas"]}
                customModalClass={styles["terms-header"]}
              >
                <div className={styles["terms-condition"]}>
                  <h3
                    className={styles["terms-header"]}
                    dangerouslySetInnerHTML={{
                      __html: termsConditionData?.header,
                    }}
                  ></h3>
                  <div
                    className={styles["terms-content"]}
                    dangerouslySetInnerHTML={{
                      __html: termsConditionData?.content,
                    }}
                  ></div>
                </div>
              </TGModal>
            )}
          </div>
        ) : (
          <ReviewPayment />
        )}
      </>
    </ResetUnlockBanner>
  );
};

export default GiftMiles;
